var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    {
      staticClass: "mx-4",
      attrs: { color: "primary", title: "Lista de ordenes" },
    },
    [
      _c(
        "v-card-title",
        [
          _c("v-text-field", {
            attrs: {
              md8: "",
              label: "Buscar",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.orderTable.searchTerm,
              callback: function ($$v) {
                _vm.$set(_vm.orderTable, "searchTerm", $$v)
              },
              expression: "orderTable.searchTerm",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "rows-per-page-items": _vm.rowsPerPageItems,
          search: _vm.orderTable.searchTerm,
          headers: _vm.headers,
          items: _vm.dataOrderDetail,
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function ({ header }) {
              return [
                _c("span", {
                  staticClass: "subheading font-weight-light text-primary",
                  domProps: { textContent: _vm._s(header.text) },
                }),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  { class: { active: props.item.orderCode === _vm.current } },
                  [
                    _c("td", {}, [_vm._v(_vm._s(props.item.orderCode))]),
                    _c("td", {}, [_vm._v(_vm._s(props.item.providerName))]),
                    _c("td", {}, [_vm._v(_vm._s(props.item.scaleFee))]),
                    _c("td", {}, [_vm._v(_vm._s(props.item.deliveryNumber))]),
                    _c(
                      "td",
                      {},
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: true,
                              round: "",
                              small: "",
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addMedicineCallback(props.item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-plus")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: true,
                              round: "",
                              small: "",
                              color: "red lighten-2",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteOrder(props.item.orderCode)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-delete")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }