<template>
  <material-card class="mx-4" color="primary" title="Lista de ordenes">
    <v-card-title>
      <v-text-field md8 v-model="orderTable.searchTerm" label="Buscar" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      :rows-per-page-items="rowsPerPageItems"
      :search="orderTable.searchTerm"
      :headers="headers"
      :items="dataOrderDetail"
    >
      <template slot="headerCell" slot-scope="{ header }">
        <span class="subheading font-weight-light text-primary" v-text="header.text"/>
      </template>
      <template v-slot:items="props">
        <tr v-bind:class="{ active: props.item.orderCode === current }">
          <td class="">{{ props.item.orderCode }}</td>
          <td class="">{{ props.item.providerName }}</td>
          <td class="">{{ props.item.scaleFee }}</td>
          <td class="">{{ props.item.deliveryNumber }}</td>
          <td class="">
            <v-btn
              :icon="true"
              round
              small
              color="primary"
              @click="addMedicineCallback(props.item)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              @click="deleteOrder(props.item.orderCode)"
              :icon="true"
              round
              small
              color="red lighten-2"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </material-card>
</template>
<script>
export default {
  props: {
    deleteOrder: {
      type: Function,
      default: () => {}
    },
    dataOrderDetail: {
      type: Array,
      default: () => []
    },
    addMedicineCallback: {
      type: Function,
      default: () => {}
    },
    current: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    orderTable: {
      searchItems: 0,
      searchTerm: ""
    },
    rowsPerPageItems: [5, 10, 15, 20],
    headers: [
      {
        sortable: false,
        text: "Orden",
        value: "orderCode",
        align: "left"
      },
      {
        sortable: false,
        text: "Prestador",
        value: "providerId",
        align: "left"
      },
      {
        sortable: false,
        text: "Valor cuota moderadora",
        value: "scaleFee",
        align: "left"
      },
      {
        sortable: false,
        text: "Número de entrega",
        value: "deliveryNumber",
        align: "left"
      },
      {
        sortable: false,
        text: "Acciones",
        value: "actions",
        align: "left",
        name: "actions"
      }
    ],
    items: []
  })
};
</script>

<style lang="css">
.theme--light.v-table tbody tr.active,
.theme--light.v-table tbody tr.active:hover {
  background-color: #b3e5fc !important;
}
</style>
